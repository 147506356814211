<template>
  <div>
    <div
      ref="imageModal"
      :class="showImageinModalPath ? 'modal fade show d-block' : 'modal fade'"
      id="imageModal"
      tabindex="-1"
      aria-labelledby="imageModalLabel"
      aria-hidden="true"
      v-show="showImageinModalPath"
    >
      <div class="modal-dialog modal-lg" id="showImageinModal">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="imageModalLabel">Preview Image</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="$emit('closeImageModel')"
            ></button>
          </div>
          <div class="modal-body">
            <div class="modal-images">
              <img
                :src="showImageinModalPath"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="showImageinModalPath ? 'modal-backdrop fade show' :''">
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { Modal } from 'bootstrap';
export default {
  name: 'imageShowModal',
  props: ['showImageinModalPath'],
  watch: {
    showImageinModalPath() {
      let modal = new Modal(this.$refs.imageModal, {});
      modal._config.backdrop = 'static';
      modal._config.keyboard = false;
      if (this.showImageinModalPath) {
        modal.show();
      } else {
        modal.hide();
      }
    },
  },
  mounted() {
  },
  created() {
  },
  methods: {
  }
};
</script>
