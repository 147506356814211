<template>
  <div class="driverDetailModal">
    <div
      ref="driverDetailModal"
      :class="driverData ? 'modal fade show d-block profileModal' : 'modal fade'"
      id="driverDetailModal"
      tabindex="-1"
      aria-labelledby="driverDetailModalLabel"
      aria-hidden="true"
      v-if="driverData"
    >
      <div class="modal-dialog modal-lg" id="driverDetailinModal">
        <div class="modal-content">
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="$emit('closeDriverModel')"
          >
            <img :src="require('@assets/images/cross.png')" alt="" />
          </button>
          <div class="modal-body">
            <div v-if="driverData != null">
              <div class="modal-profile-body">
                <h2>Driver profile</h2>
                <div class="mp-info">
                  <figure class="userImg">
                    <avatar
                      class="w-100 h-100"
                      v-if="driverData.image_url == null"
                      :username="driverData.full_name"
                    ></avatar>
                    <img v-if="driverData.image_url != null" :src="driverData.image_url" alt="" />
                  </figure>
                  <div class="detail">
                     <h5>{{ driverData.full_name }}</h5>
                    <ul class="current-info">
                      <li>
                        Member since:
                        <span> {{ moment(driverData.created_at).format('Do MMM YYYY') }}</span>
                      </li>
                      <li v-if="driverData.location_suburb">
                        <figure>
                          <img :src="require('@assets/images/greylocator.png')" alt="" />
                        </figure>
                        {{ driverData.location_suburb }}
                      </li>
                    </ul>
                    <p>
                      {{ driverData.profile_description ? driverData.profile_description : '-' }}
                    </p>
                    <div class="special-info">
                      <div class="speciality">
                        <h4>Specialities :</h4>
                        <p>{{ driverData.specialty ? driverData.specialty : '-' }}</p>
                      </div>
                      <div class="rating">
                        <h4>RATING</h4>
                        <div class="d-flex">
                          <star-rating
                            :star-size="16"
                            :increment="0.5"
                            :rating="parseFloat(driverData.user_driver_rating_review_avg)"
                            read-only
                            :show-rating="false"
                            active-color="#00A6E3"
                          ></star-rating>
                          <span class="ps-2">
                            {{ Number(driverData.user_driver_rating_review_avg) }}/5
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="vehicle-info">
                      <div class="vehicle">
                        <h4>VEHICLES</h4>
                        <ul>
                          <li
                            v-for="v_detail in driverData.driver_vehicles_detail"
                            v-bind:key="v_detail.id"
                          >
                            <figure>
                              <img
                                :src="v_detail.image_url"
                                alt=""
                                style="filter: brightness(0%)"
                              />
                            </figure>
                            <span>{{ v_detail.name }}</span>
                          </li>
                        </ul>
                      </div>
                      <div class="document">
                        <h4 class="invisible">VERIFIED Documentation</h4>
                      </div>
                      <!-- <div class="document">
                        <h4>VERIFIED Documentation</h4>
                        <ul>
                          <li
                            v-for="verification in driverData.driver_documents_verified_or_not"
                            v-bind:key="verification.key"
                          >
                            <figure v-if="verification.key == 'abn'">
                              <img :src="require('@assets/images/abn.png')" alt="" />
                            </figure>
                            <span>{{ verification.key ? verification.key.toUpperCase() : '-' }}
                            </span>
                          </li>
                        </ul>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="modal-work-history"
                v-if="driverData.user_has_many_work_history.length > 0"
              >
                <h2>Work history</h2>
                <ul>
                  <li
                    v-for="history in driverData.user_has_many_work_history"
                    v-bind:key="history.id"
                  >
                    <span></span>
                    <div class="hinfo">
                      <h5 class="d-flex">
                        {{ history.title }} {{ history.start_date_year }}
                        <p v-if="history.end_date_year != null">-</p>
                        {{ history.end_date_year }}
                      </h5>
                      <p>
                        {{ history.description }}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <button
            type="button"
            class="modal-profile-close"
            data-bs-dismiss="modal"
            @click="$emit('closeDriverModel')"
          >
            Close
          </button>
        </div>
      </div>
    </div>
    <div :class="driverData ? 'modal-backdrop fade show' : ''"></div>
  </div>
</template>

<script>
/* eslint-disable */
import { Modal } from 'bootstrap';
import Verification from '../views/pages/DriverComponents/Verification.vue';
import Avatar from 'vue-avatar';
import StarRating from 'vue-star-rating';
import moment from 'moment';
export default {
  name: 'driverDetailShowModal',
  components: { StarRating, Verification, Avatar },
  props: ['driverData'],
  mounted() {},
  created() {},
  methods: {
    moment,
  },
};
</script>
<style lang="scss" scoped>
.modal-header {
  border-bottom: 0 none;
  padding-bottom: 0rem !important;
}
.modal-body {
  padding-top: 0rem;
}
</style>
