<template>
  <div class="booking-main" :class="{ 'dpv-main': showDriver == 11 }"
  v-bind:style="{ height: setheight + 'px' }"
  >
    <!-- v-bind:style="{ height: setheight + 'px' }" -->
    <!-- <div class="dashboard-header">
        <ul>
          <li>
            <router-link to="/request-driver" tag="a">Post a job</router-link>
          </li>
          <li>
            <a class="bg-success" v-if="is_driver == 1">Registered as a driver</a>
            <a v-on:click="registerDriver" v-else>Register as a driver</a>
          </li>
        </ul>
      </div> -->
    <div class="booking-body">
      <!-- <h4>All Bookings</h4> -->
      <div class="dashboard-header booking-header">
        <div class="bh-form">
          <div class="form-group search-bar">
            <input
              type="text"
              class="form-control"
              placeholder="Search your booking here  #Title"
              @keyup="searchBooking"
              v-model="serverParams.search"
            />
          </div>
          <div class="form-group selection">
            <select
              class="form-select"
              v-model="serverParams.filterByVehicle"
              @change="searchBooking"
            >
              <option value="">Vehicle</option>
              <option
                v-for="option in this.vehicle_option"
                v-bind:key="option.id"
                v-bind:value="option.id"
              >
                {{ option.name }}
              </option>
            </select>
          </div>
          <!-- <v-date-picker
              v-model="serverParams.filterByDate"
              :model-config="{
                type: 'string',
                mask: 'YYYY-MM-DD',
              }"
              class="booking-date"
              mode="date"
              :masks="{ L: 'YYYY-MM-DD' }"
              @input="searchBooking"
            >
              <template #default="{ inputValue, togglePopover }">
                <div class="form-group" @click="togglePopover()">
                  <input
                    :value="inputValue"
                    class="form-control"
                    v-on:keyup="queryForKeywords"
                    :readonly="deleteDateFilter != 1"
                    placeholder="Date"
                  />
                </div>
              </template>
            </v-date-picker> -->
          <v-date-picker
            v-model="serverParams.filterByDate"
            mode="date"
            :model-config="{
              type: 'string',
              mask: 'YYYY-MM-DD',
            }"
            :masks="{ L: 'DD-MM-YYYY' }"
            @input="searchBooking"
            :update-on-input="false"
          >
            <template #default="{ inputValue, togglePopover, inputEvents }">
              <div class="booking-date">
                <div class="form-group" @click="togglePopover()">
                  <input
                    @keydown.enter.prevent=""
                    placeholder="Date (dd-mm-yyyy)"
                    class="form-control"
                    aria-describedby="addon-wrapping"
                    :value="inputValue"
                    v-on="inputEvents"
                  />
                </div>
              </div>
            </template>
          </v-date-picker>
          <router-link to="/request-driver" tag="button" class="btn btn-primary"
            >Create new booking</router-link
          >
        </div>
      </div>
      <div class="booking-fold" :class="{ active: currentBooking != null }">
        <div class="booking-table table-responsive">
          <table class="table" ref="tableRef">
            <thead>
              <tr>
                <th>Reference no</th>
                <th>
                  <div
                    class="option-heading"
                    :class="{
                      active:
                        serverParams.orderByField == 'service_date' &&
                        serverParams.orderByType == 'asc',
                    }"
                    @click="sortBooking('service_date')"
                  >
                    Service date
                  </div>
                </th>
                <th>Title</th>
                <th>
                  <div>Status</div>
                </th>
                <th>Vehicle</th>
                <th>
                  <div
                    class="option-heading"
                    :class="{
                      active:
                        serverParams.orderByField == 'pickup_address' &&
                        serverParams.orderByType == 'asc',
                    }"
                    @click="sortBooking('pickup_address')"
                  >
                    Pick-up
                  </div>
                </th>
                <th>
                  <div
                    class="option-heading"
                    :class="{
                      active:
                        serverParams.orderByField == 'drop_address' &&
                        serverParams.orderByType == 'asc',
                    }"
                    @click="sortBooking('drop_address')"
                  >
                    Drop-off
                  </div>
                </th>
                <th>
                  <div
                    class="option-heading"
                    :class="{
                      active:
                        serverParams.orderByField == 'suggested_budget_amount' &&
                        serverParams.orderByType == 'asc',
                    }"
                    @click="sortBooking('suggested_budget_amount')"
                  >
                    Price (AUD)
                  </div>
                </th>
                <th>
                  <div>Action</div>
                </th>
              </tr>
            </thead>
            <tbody v-if="rows.length > 0 && !fetchingData">
              <tr
                v-for="(booking, index) in rows"
                v-bind:key="index"
                @click="showBooking(+booking.id)"
                style="cursor: pointer"
                :class="
                  currentBooking == booking.id
                    ? 'nth-child-border'
                    : !currentBooking && index == 0
                    ? 'first-child-border'
                    : ''
                "
              >
                <!-- <td>
                    {{ tableInfo.currentPage * tableInfo.perPage - tableInfo.perPage + index + 1 }}
                  </td> -->
                <td style="color: #00a6e3 !important">
                  {{ booking.booking_reference_id }}
                </td>
                <td>{{ allBookingDate(booking.service_date, booking.service_time) }}</td>
                <td>{{ booking.title_of_booking }}</td>
                <td
                  class="ongoing status position-relative"
                  :class="
                    booking.booking_status == '1'
                      ? 'text-info'
                      : booking.booking_status == '2'
                      ? 'complete status'
                      : 'text-primary'
                  "
                >
                  {{ booking.booking_status_name }}
                  <span
                    class="badge rounded-pill bg-primary"
                    data-bs-toggle="tooltip"
                    data-bs-html="true"
                    title="Total bids"
                    v-if="
                      booking.booking_status == 0 &&
                      Array.isArray(booking.booking_has_many_placed_bids_new) &&
                      booking.booking_has_many_placed_bids_new.length > 0
                    "
                  >
                    <b>{{ booking.booking_has_many_placed_bids_new.length }}</b>
                  </span>
                </td>
                <td>{{ booking.booking_vehicles_detail.join(', ') }}</td>
                <td v-if="booking.pickup_address && booking.pickup_address.length < 30">
                  {{ booking.pickup_address }}
                </td>
                <td v-else-if="booking.pickup_address">
                  {{ booking.pickup_address.substring(0, 30) + '..' }}
                </td>
                <td v-if="booking.drop_address && booking.drop_address.length < 30">
                  {{ booking.drop_address }}
                </td>
                <td v-else-if="booking.drop_address">
                  {{ booking.drop_address.substring(0, 30) + '..' }}
                </td>
                <td>{{ booking.suggested_budget_amount }}</td>
                <td>
                  <div class="action" v-if="booking.booking_status == 0">
                    <button
                      :disabled="isDeletetingBooking"
                      class="bg-transparent border-0"
                      @click.stop
                      @click="(deleteBookingIndex = index), (deleteBookingId = +booking.id)"
                      data-bs-toggle="modal"
                      data-bs-target="#deleteBookingModal"
                    >
                      <img
                        style="height: 18px; width: 17px"
                        :src="require('@assets/images/trash.svg')"
                        alt=""
                      />
                    </button>
                    <router-link
                      :to="{ name: 'edit-booking', params: { booking_id: +booking.id } }"
                      tag="button"
                      class="bg-transparent border-0"
                      @click.prevent="showBooking()"
                    >
                      <img
                        style="height: 18px; width: 17px"
                        :src="require('@assets/images/edit.svg')"
                        alt=""
                      />
                    </router-link>
                  </div>
                  <span v-else :class="currentBooking ? '' : 'ms-3'">
                    <img :src="require('@assets/images/eye-black.svg')" class="eye-class" alt="" />
                  </span>
                </td>
              </tr>
            </tbody>
            <tbody v-if="fetchingData">
              <tr>
                <td colspan="100%" style="text-align: center">
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-if="rows.length == 0 && !fetchingData">
              <tr>
                <td colspan="100%" style="text-align: center">
                  <img
                    class="noDataImage"
                    :src="require('@/assets/images/nodata.svg')"
                    alt=""
                  /><br /><br />
                  <b>No data to show</b>
                </td>
              </tr>
            </tbody>
          </table>
          <pagination
            v-if="rows.length > 0 && !fetchingData"
            :perPage="serverParams.perPage"
            :totalRows="tableInfo.total"
            :value="serverParams.page"
            v-on:pagechanged="pagechanged"
            v-on:perPagechanged="perPagechanged"
          ></pagination>
        </div>
        <div class="booking-flow flex-fill" v-if="isFetchingBooking">
          <div
            class="spinner-border text-primary mx-auto"
            style="position: absolute; left: 0; top: 0; right: 0; bottom: 0; margin: auto"
          ></div>
        </div>
        <div
          class="booking-flow flex-fill"
          ref="bookingFlow"
          v-if="Bookingdata != null && !isFetchingBooking && showDriver != 11"
          style="position: fixed !important; height: 100%;"
        >
          <!-- <div v-bind:style="{ height: boxSetHeight + 'px' }"> -->
          <div class="booking-map">
            <figure style="height: 100% !important">
              <div
                class="spinner-border text-primary mx-auto mt-5"
                v-if="isFatchMapImage"
                style="position: absolute; left: 50%; top: 10%"
              ></div>
              <img
                v-if="location_map_image_url != null"
                :src="location_map_image_url"
                alt=""
                @error="location_map_image_url = googleMapPath"
              />
            </figure>
            <a
              class="back-link"
              style="position: absolute; cursor: pointer; left: 10px; top: 20px"
              @click="showBooking(+Bookingdata.id)"
            >
              <figure style="height: 25px">
                <img
                  style="background: white; border-radius: 100%"
                  :src="require('@assets/images/back-circle-arrow.png')"
                  alt="back"
                />
              </figure>
            </a>
            <div class="booking-map-info">
              <div class="refrence-num" v-if="Bookingdata.booking_reference_id">
                Reference: {{ Bookingdata.booking_reference_id }}
                <img
                  :src="require('@assets/images/copy.svg')"
                  style="cursor: pointer"
                  @click="referenceNoCopyToClipBoard(Bookingdata.booking_reference_id)"
                />
              </div>
              <!-- {{pickup_latitude pickup_longitude drop_latitude drop_longitude}} -->
              <a
                :href="`https://www.google.com/maps/dir/?api=1&origin=${Bookingdata.pickup_latitude},${Bookingdata.pickup_longitude}&destination=${Bookingdata.drop_latitude},${Bookingdata.drop_longitude}`"
                class="view-map"
                target="_blank"
                >View map</a
              >
            </div>
          </div>
          <div class="booking-detail">
            <div class="shipping-info">
              <h2>{{ Bookingdata.title_of_booking }}</h2>
              <div class="track">
                <ul>
                  <li>
                    <figure>
                      <img
                        :src="require('@assets/images/pin-sharp-circle.svg')"
                        alt=""
                        style="
                          filter: invert(27%) sepia(66%) saturate(2625%) hue-rotate(346deg)
                            brightness(100%) contrast(84%);
                        "
                      />
                    </figure>
                    <div class="track-location">
                      <h4>PICK-UP LOCATION</h4>
                      <span>{{ Bookingdata.pickup_address }}</span>
                    </div>
                  </li>
                  <li v-if="Bookingdata.drop_address">
                    <figure>
                      <img
                        :src="require('@assets/images/pin-sharp-dot.svg')"
                        alt=""
                        style="
                          filter: invert(56%) sepia(93%) saturate(676%) hue-rotate(32deg)
                            brightness(90%) contrast(101%);
                        "
                      />
                    </figure>
                    <div class="track-location">
                      <h4>DROP-OFF LOCATION</h4>
                      <span>{{ Bookingdata.drop_address }}</span>
                    </div>
                  </li>
                </ul>
                <div class="budget">
                  <h4>Task Budget</h4>
                  <span>{{ Bookingdata.suggested_budget_amount }}</span>
                </div>
              </div>
              <div class="service-date">
                <figure>
                  <img :src="require('@assets/images/calendar.svg')" alt="" />
                </figure>
                <div class="track-location">
                  <h4>Service time and date</h4>
                  <span>
                        {{Bookingdata.service_time1}},
                        {{ moment(Bookingdata.service_date).format('dddd Do MMMM YYYY') }}
                  </span>
                </div>
              </div>
              <div class="detail">
                <label>Details</label>
                <p>
                  {{ Bookingdata.description }}
                </p>
              </div>
              <div class="availble-photos" v-show="Bookingdata.attechments.length > 0">
                <label>photos</label>
                <div class="av-photo-list">
                  <figure
                    :key="`b-attachemnts-${key}`"
                    v-for="(attachment, key) in Bookingdata.attechments"
                  >
                    <a
                      @click="deleteAttachment(attachment.id)"
                      style="
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        top: -13px;
                        right: -9px;
                        cursor: pointer;
                      "
                    >
                      <img
                        :src="require('@assets/images/cross-circle.png')"
                        alt=""
                        style="width: 100%; height: 100%; object-fit: contain"
                      />
                    </a>
                    <img
                      v-if="
                        attachment.image_url != null &&
                        attachment.attechment_name.split('.').pop().toLowerCase() == 'jpg' ||
                        attachment.attechment_name.split('.').pop().toLowerCase() == 'jpeg' ||
                        attachment.attechment_name.split('.').pop().toLowerCase() == 'png' ||
                        attachment.attechment_name.split('.').pop().toLowerCase() == 'svg'
                      "
                      :src="attachment.image_url"
                      alt=""
                      style="cursor: pointer !important"
                      @click="openModalForShowImage(attachment.image_url)"
                    />
                    <a :href="attachment.image_url" target="_blank"  v-else-if="
                          attachment.image_url != null &&
                          attachment.attechment_name.split('.').pop().toLowerCase() == 'pdf'
                        ">
                      <img
                        :src="require('@assets/images/pdf-file.png')"
                        alt=""/>
                    </a>
                    <a :href="attachment.image_url" target="_blank"
                        v-else-if="
                          attachment.image_url != null &&
                          attachment.attechment_name.split('.').pop().toLowerCase() == 'xlsx' ||
                          attachment.attechment_name.split('.').pop().toLowerCase() == 'xls' ||
                          attachment.attechment_name.split('.').pop().toLowerCase() == 'csv'
                        "
                    >
                      <img
                        :src="require('@assets/images/excel.png')"
                        alt=""/>
                    </a>
                    <a :href="attachment.image_url" target="_blank"
                        v-else-if="
                          attachment.image_url != null &&
                          attachment.attechment_name.split('.').pop().toLowerCase() == 'docx' ||
                          attachment.attechment_name.split('.').pop().toLowerCase() == 'doc'
                        "
                    >
                      <img
                        :src="require('@assets/images/doc.png')"
                        alt=""/>
                    </a>
                    <a :href="attachment.image_url" target="_blank"
                        v-else-if="attachment.image_url != null"
                    >
                      <img
                        :src="require('@assets/images/file.png')"
                        alt=""/>
                    </a>
                  </figure>
                </div>
              </div>
            </div>
            <div class="bids-detail">
              <h5 v-if="Bookingdata.booking_status_name != 'Completed'">
                Bids<span>({{ totalBids }})</span>
              </h5>
              <h5 v-else>Completed by</h5>
              <div
                v-show="typeof Bidsdata !== 'undefined' && Bidsdata.length > 0"
                class="bids-list"
                v-for="bid in Bidsdata"
                :key="bid.id"
                :style="
                  bid.user.id == currentDriverId
                    ? { border: '1px solid #00A6E3' }
                    : { border: 'unset' }
                "
              >
                <div
                  class="bid-item"
                  v-if="
                    Bookingdata.booking_status_name != 'Completed' ||
                    (Bookingdata.booking_status_name == 'Completed' && completed_by == bid.id)
                  "
                >
                  <div class="bid-active">
                    <small
                      >Active 4h ago
                      <span class="text-danger ps-2" v-if="bid.bid_status == 3">Rejected</span>
                    </small>
                    <div class="bid-user">
                      <figure>
                        <avatar
                          v-if="bid.user.image_url == null"
                          :username="bid.user.full_name"
                          class="w-auto h-100"
                        ></avatar>
                        <img v-if="bid.user.image_url != null" :src="bid.user.image_url" alt="" />
                      </figure>
                      <div class="bu-info">
                        <h5>{{ bid.user.full_name }}</h5>
                        <span>{{ bid.user.location }}</span>
                      </div>
                    </div>
                    <!-- <div class="d-flex">
                        <div>
                          <a @click="showDriverProfile(+bid.booking_id, +bid.user_id,11)">
                            VIEW BID
                          </a>
                        </div>
                        <div>
                          <a @click="showDriverProfile(+bid.booking_id, +bid.user_id,1)"
                          :style="(isFetchingDriver&& bid.user.id == currentDriverId &&
                          showDriver == 1)
                          ? {'display':'none'} : {'display':'block'}"> VIEW PROFILE  </a>
                        </div>
                      </div> -->

                    <div
                      class="spinner-border spinner-border-sm text-primary mx-auto"
                      v-if="isFetchingDriver && bid.user.id == currentDriverId && showDriver == 1"
                      style="position: absolute; margin: auto; left: 50%"
                    ></div>
                  </div>
                  <div class="bid-job">
                    <small>{{ bid.user.driver_total_booking }} jobs</small>
                    <div class="bid-rating">
                      <span>{{ Number(bid.user.user_driver_rating_review_avg) }}/5</span>
                      <div class="ratiing-star">
                        <i class="fas fa-star"></i>
                      </div>
                    </div>
                    <div class="price">{{ bid.bid_amount }} <span>AUD</span></div>
                  </div>
                  <div class="view-driver-profile">
                    <div class="d-flex button">
                      <div>
                        <a @click="showDriverProfile(+bid.booking_id, +bid.user_id, 11)">
                          VIEW BID
                        </a>
                      </div>
                      <div>
                        <a
                          @click="showDriverProfile(+bid.booking_id, +bid.user_id, 1)"
                          :style="
                            isFetchingDriver && bid.user.id == currentDriverId && showDriver == 1
                              ? { display: 'none' }
                              : { display: 'block' }
                          "
                        >
                          VIEW PROFILE
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="Bookingdata.booking_status_name == 'Completed'">
                <form class="mt-3" method="post" @submit.prevent="onSubmit">
                  <star-rating
                    :star-size="18"
                    :increment="0.5"
                    active-color="#00A6E3"
                    :rating="parseFloat(givenrating)"
                    @rating-selected="setRating"
                    :read-only="alreadyGiveReview"
                  ></star-rating>
                  <input
                    type="text"
                    class="form-control mb-2 mt-2"
                    placeholder="Add review"
                    v-model="form.review"
                    v-if="!alreadyGiveReview"
                  />
                  <span v-if="alreadyGiveReview && form.review">{{ form.review }}</span>
                  <span v-if="alreadyGiveReview">- You</span>
                  <div class="bid-button">
                    <button
                      type="submit"
                      class="btn btn-primary"
                      v-if="!alreadyGiveReview"
                      :disabled="isReviewSubmit"
                    >
                      <span
                        v-if="isReviewSubmit"
                        class="review-spinner spinner-border"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span v-if="!isReviewSubmit">Submit review</span>
                    </button>
                  </div>
                </form>
                <div class="mt-3" v-if="showDriverRating">
                  <star-rating
                    class="mb-2"
                    :star-size="18"
                    :increment="0.5"
                    active-color="#00A6E3"
                    :rating="parseFloat(Bookingdata.getting_by_user.rating)"
                    read-only
                  ></star-rating>
                  <span v-if="Bookingdata.getting_by_user.review" class="book-get"
                    >{{ Bookingdata.getting_by_user.review }}<br />
                    - {{ Bookingdata.bid_completed_by.user.full_name }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="booking-action" :class="{ hide: currentBooking != null }">
            <div class="upload-file" v-if="Bookingdata.booking_status_name != 'Completed'">
              <label for="formFile" class="form-label">Add attachment(s)</label>
              <!-- <input
                  type="file"
                  ref="add_attachments"
                  @change="onAttachFileChanged"
                  style="display: none"
                />
                <button
                  :loading="isSelecting"
                  @click="handleFileImport"
                  class="form-label"
                  id="formFile"
                >
                  Add attachment(s)
                </button> -->
              <input
                class="form-control"
                multiple
                @change="onAttachFileChanged"
                type="file"
                id="formFile"
                data-bs-toggle="tooltip"
                data-bs-html="true"
                title="Allowed format are : JPEG, JPG, PNG, PDF, XLSX, DOC, etc..."
                style="color: transparent"
              />
            </div>
            <!-- <div class="upload-file" v-if="Bookingdata.booking_status_name == 'Completed'">
              <button
                type="submit"
                class="btn btn-primary"
                @click="generateInvoice(currentBooking)"
              >
                <span
                  v-if="isPDFGenerate"
                  class="review-spinner spinner-border"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span v-if="!isPDFGenerate">Generate invoice</span>
              </button>
            </div> -->
          </div>
        <!-- </div> -->
        </div>
        <div class="booking-flow flex-fill" v-if="showDriver == 11">
            <div class="bf-header">
              <a
                class="back-link"
                href="javascript:;"
                @click="getspecificBooking(driverData.booking_id)"
              >
                <figure>
                  <img :src="require('@assets/images/back-circle-arrow.png')" alt="" />
                </figure>
                Back to booking details
              </a>
              <div class="bf-tag">
                <div class="bf-price">
                  <span>price</span>
                  <h5>{{ driverData.bid_amount }}</h5>
                  <h6>AUD</h6>
                </div>
                <div class="bf-communication">
                  <figure>
                    <img :src="require('@assets/images/talking.png')" alt="" />
                  </figure>
                  <span>Communication inside the platform</span>
                </div>
              </div>
            </div>
            <div class="bf-body">
              <div class="bf-profile">
                <h4>Driver Profile</h4>
                <div class="profile-info">
                  <figure>
                    <avatar
                      class="w-100 h-100"
                      v-if="driverData.user.image_url == null"
                      :username="driverData.user.full_name"
                    ></avatar>
                    <img
                      v-if="driverData.user.image_url != null"
                      :src="driverData.user.image_url"
                      alt=""
                    />
                  </figure>
                  <div class="profile-wrapper">
                    <div class="profile-des">
                      <h5>{{ driverData.user.full_name }}</h5>
                      <div class="status">
                        <span v-if="driverData.user.user_has_one_current_location">
                          Last Active: {{
                            moment(
                              driverData.user.user_has_one_current_location.created_at
                              ).fromNow()
                          }}
                        </span>
                        <span v-if="driverData.user.last_live_location_suburb">
                          Last seen in: {{ driverData.user.last_live_location_suburb }}
                        </span>
                      </div>
                      <p>
                        {{ driverData.user.profile_description }}
                      </p>
                    </div>
                    <div class="bf-other-info">
                      <!-- <Verification
                        :verification-data="driverData.driver_documents_verified_or_not"
                      /> -->
                      <div class="vehicle" v-if="driverData.user.driver_vehicles_detail">
                        <h4 style="padding-left: 9%;">VEHICLES</h4>
                        <div class="vehicl-list">
                          <div
                            class="vehicle-img"
                            v-for="v_detail in driverData.user.driver_vehicles_detail"
                            v-bind:key="v_detail.id"
                          >
                            <figure>
                              <img :src="v_detail.image_url" alt="" />
                            </figure>
                            <span>{{ v_detail.name }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="speciality">
                        <h4>Languages :</h4>
                        <p>
                          {{ driverData.user.languages ? driverData.user.languages : '-' }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="booking-action hide">
                  <div
                    class="bid-option"
                    v-if="driverData.bid_status == '0' && isShowAccptRejectButton1"
                  >
                    <router-link
                      :to="{
                        name: 'payment',
                        params: { bookingId: driverData.booking_id, bidId: driverData.user_id },
                      }"
                      tag="button"
                      class="btn btn-primary"
                    >
                      Accept bid
                    </router-link>

                    <button
                      type="button"
                      :disabled="isRejectBid == 1"
                      class="btn btn-reject"
                      @click="RejectBid()"
                    >
                      <span
                        v-if="isRejectBid == 1"
                        class="spinner-border spinner-border-lg"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span v-if="isRejectBid !== 1">Reject bid</span>
                    </button>
                  </div>
                  <div v-if="!isShowAccptRejectButton1">
                    <span>Bid active time is expire</span>
                  </div>
                  <button
                    type="button"
                    class="btn btn-msg"
                    @click="callMessage(driverData.booking_id, driverData.user_id)"
                  >
                    <figure><img :src="require('@assets/images/comment.png')" /></figure>
                    Message
                  </button>
                </div>
                <div class="bf-work-info" v-if="driverData.user_has_many_work_history.length > 0">
                  <h4>Work History</h4>
                  <ul>
                    <li
                      v-for="history in driverData.user_has_many_work_history"
                      v-bind:key="history.id"
                    >
                      <h3>
                        {{ history.title }} {{ history.start_date_year }}
                        <span v-if="history.end_date_year != null">-</span>
                        {{ history.end_date_year }}
                      </h3>
                      <p>
                        {{ history.description }}
                      </p>
                    </li>
                  </ul>
                </div>
                <div class="bf-reviews" v-if="driverData.user_has_many_rating_review.length > 0">
                  <h4>Reviews</h4>
                  <div
                    class="review-list"
                    v-for="review in driverData.user_has_many_rating_review"
                    v-bind:key="review.id"
                  >
                    <div class="rating d-flex">
                      <star-rating
                        :star-size="16"
                        :increment="0.5"
                        :rating="parseFloat(review.rating)"
                        read-only
                        :show-rating="false"
                        active-color="#00A6E3"
                      ></star-rating>
                      <span class="ml-2">{{ Number(review.rating) }}/5</span>
                    </div>
                    <p>
                      {{ review.review }}
                    </p>
                    <label>
                      {{ review.given_user_detail ? `-${review.given_user_detail.full_name}` : '' }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="booking-action hide">
              <div class="bid-option" v-if="driverData.bid_status == '0'
              && isShowAccptRejectButton1">
                <router-link
                  :to="{
                    name: 'payment',
                    params: { bookingId: driverData.booking_id, bidId: driverData.user_id },
                  }"
                  tag="button"
                  class="btn btn-primary"
                >
                  Accept bid
                </router-link> -->

                <!-- <button
                    type="button"
                    :disabled="isRejectBid == 2"
                    class="btn btn-primary"
                    @click="acceptRejectBid(2)"
                  >
                    <span
                      v-if="isRejectBid == 2"
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Accept Bid
                  </button> -->
                <!-- <button
                  type="button"
                  :disabled="isRejectBid == 1"
                  class="btn btn-reject"
                  @click="RejectBid()"
                >
                  <span
                    v-if="isRejectBid == 1"
                    class="spinner-border spinner-border-lg"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span v-if="isRejectBid !== 1">Reject bid</span>
                </button>
              </div>
              <div v-if="!isShowAccptRejectButton1">
                <span>Bid active time is expire</span>
              </div>
              <button
                type="button"
                class="btn btn-msg"
                @click="callMessage(driverData.booking_id, driverData.user_id)"
              >
                <figure><img :src="require('@assets/images/comment.png')" /></figure>
                Message
              </button>
            </div> -->
        </div>
        <!--<div class="spinner-border text-primary mx-auto mt-5" v-if="isFetchingBooking"></div>-->
      </div>
    </div>
    <div
      class="modal fade"
      id="deleteBookingModal"
      tabindex="-1"
      aria-labelledby="deleteBookingModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <button
              id="deleteBookingModalClose"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <h6>Are you sure you want to delete this booking request?</h6>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="CloseModel">Cancel</button>
            <button
              type="button"
              class="btn btn-secondary btn-danger"
              @click="deleteBooking(+deleteBookingId, deleteBookingIndex)"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->
    <driverDetailShowModal
      :driverData="driverData"
      v-on:closeDriverModel="
        driverData = '';
        currentDriverId = '';
      "
      v-if="showDriver == 1"
    >
    </driverDetailShowModal>
    <imageShowModal
      :showImageinModalPath="showImageinModalPath"
      v-on:closeImageModel="showImageinModalPath = ''"
    ></imageShowModal>
  </div>
</template>

<script>
/* eslint-disable */
import moment from 'moment';
import Avatar from 'vue-avatar';
import StarRating from 'vue-star-rating';
import pagination from '@/components/pagination';
import Verification from '@/views/pages/DriverComponents/Verification';
import imageShowModal from '@/components/ImageShowModal';
import driverDetailShowModal from '@/components/driverDetailShowModal';

export default {
  name: 'Bookings',
  components: {
    Avatar,
    StarRating,
    Verification,
    pagination,
    imageShowModal,
    driverDetailShowModal,
  },
  data() {
    return {
      rows: [],
      Bookingdata: null,
      driverData: null,
      totalBids: 0,
      Bidsdata: {},
      tableInfo: {},
      totalRecords: 0,
      vehicle_option: [],
      showDriver: 0,
      booking_vehicles_detail: '',
      form: {
        rating: 0,
        review: '',
      },
      serverParams: {
        search: '',
        filterByDate: '',
        filterByVehicle: '',
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        page: 1,
        perPage: 10,
      },
      currentBooking: null,
      fetchingData: false,
      isFetchingBooking: false,
      isRejectBid: 0,
      is_driver: localStorage.getItem('is_driver'),
      // 0 is Request | 1 is Accept | 2 is Complete | 3 is Close by Customer | 4 is close by Driver | 5 is Close by Admin
      status: {
        Accept: 1,
        Complete: 2,
      },
      attachFile: null,
      isSelecting: false,
      selectedFile: null,
      deleteDateFilter: 0,
      showImageinModalPath: '',
      isFatchMapImage: false,
      location_map_image_url: '',
      isReviewSubmit: false,
      completed_by: 0,
      givenrating: 0,
      alreadyGiveReview: false,
      isPDFGenerate: false,
      isShowAccptRejectButton: false,
      googleMapPath: `${process.env.VUE_APP_HOME_REDIRECT}google_static_map/map.png`,
      isDeletetingBooking: false,
      showDriverRating: false,
      showInvalidFileError: false,
      isFetchingDriver: false,
      deleteBookingId: 0,
      deleteBookingIndex: 0,
      currentDriverId: '',
      bookingFlow: null,
      setheight: window.innerHeight,
      boxSetHeight: window.innerHeight,
      tableHeight: null,
      tableRef: null,
    };
  },
  watch: {
    page() {
      this.getbooking();
    },
  },
  computed: {
    page() {
      this.serverParams.page = 1;
      this.serverParams.perPage = 10;
      return this.$route.query.status || 'all';
    },
    isShowAccptRejectButton1() {
      if (this.driverData && this.driverData.bid_active_time) {
        if (
          this.driverData.bid_active_time > moment(new Date()).utc().format('YYYY-MM-DD HH:mm:ss')
        ) {
          return true;
        }
      } else if (this.driverData) {
        return true;
      }
      return false;
    },
  },
  created() {
    this.getbooking();
    this.getVehicles();
    var userdata = JSON.parse(localStorage.getItem('userData'));
    const roleDriver = userdata['roles'].filter((e) => e.name.toLowerCase() == 'driver');
    if (roleDriver.length > 0) {
      this.is_driver = 1;
    } else if (localStorage.getItem('is_driver')) {
      this.is_driver = 1;
    } else {
      this.is_driver = 0;
    }
  },
  methods: {
    moment,
    generateInvoice(id) {
      this.isPDFGenerate = true;
      this.$http
        .post('/customer/generate-invoice', { id: id })
        .then((res) => {
          const url = res.data.data.invoice_pdf_url;
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('href', url);
          link.setAttribute('target', '_blank');
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
        })
        .finally(() => {
          this.isPDFGenerate = false;
        });
    },
    getVehicles() {
      this.$http
        .get('/getVehicalTypes')
        .then((res) => {
          this.vehicle_option = res.data.data;
        })
        .catch((error) => {
          this.$toast.error('Vehicle not found');
        })
        .finally(() => {});
    },
    getbooking() {
      this.fetchingData = true;
      this.currentBooking = null;
      this.$http
        .get('/customer/booking/list', {
          params: Object.assign(
            { ...this.serverParams },
            this.page == 'all'
              ? {}
              : {
                  status: [this.status[this.$route.query.status]],
                }
          ),
        })
        .then((res) => {
          const { collection, ...info } = res.data.data;
          this.rows = collection;
          this.tableInfo = info;
          if (this.$route.params.from && this.$route.params.from == 'view-bids') {
            this.showBooking(this.rows[0].id);
          }
          if (this.$route.params.from && this.$route.params.from == 'alert-modal') {
            this.showBooking(this.$route.params.booking_id);
          }
          if (this.$route.query.booking_id) {
            this.showBooking(this.$route.query.booking_id);
          }
        })
        .catch((error) => {
          this.rows = [];
          this.$toast.error(error.response.data.message);
        })
        .finally(() => {
          this.fetchingData = false;
        });
    },
    allBookingDate(date, time) {
      const allBookingDBUTC = `${date} ${time}`;
      const allBookingDBLocal = moment.utc(allBookingDBUTC).local().format('YYYY-MM-DD HH:mm:ss');
      return allBookingDBLocal.substring(0, allBookingDBLocal.indexOf(' '));
    },
    registerDriver() {
      this.fetchingData = true;
      this.$http
        .post('/customer/register-as-a-driver')
        .then((res) => {
          this.is_driver = 1;
          localStorage.setItem('is_driver', 1);
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
        })
        .finally(() => {
          this.fetchingData = false;
        });
    },
    getspecificBooking(bookingId) {
      this.showDriver = 0;
      this.isFetchingBooking = true;
      this.showDriverRating = false;
      this.isFatchMapImage = false;
      this.$http
        .get(`/customer/booking/show/${bookingId}`)
        .then((res) => {
          const collection1 = res.data.data;
          var given_by_customer = collection1.given_by_user;
          if (!Array.isArray(given_by_customer)) {
            (this.givenrating = given_by_customer.rating),
              (this.form.review = given_by_customer.review);
            this.alreadyGiveReview = true;
          } else {
            (this.givenrating = 0), (this.form.review = '');
            this.alreadyGiveReview = false;
          }
          if (this.alreadyGiveReview && !Array.isArray(res.data.data.getting_by_user)) {
            this.showDriverRating = true;
          }
          this.Bookingdata = collection1;
          const serviceDateTimeDBUTC = `${collection1.service_date} ${collection1.service_time}`;
          const serviceDateTimeDBLocal = moment
            .utc(serviceDateTimeDBUTC)
            .local()
            .format('YYYY-MM-DD HH:mm:ss');

          //am pm without space
          this.Bookingdata.service_time1 = moment(
            serviceDateTimeDBLocal.substring(serviceDateTimeDBLocal.indexOf(' ') + 1),
            ['HH.mm']
          ).format('hh:mma');

          //am pm with space
          this.Bookingdata.service_time = moment(
            serviceDateTimeDBLocal.substring(serviceDateTimeDBLocal.indexOf(' ') + 1),
            ['HH.mm']
          ).format('hh:mm a');
          this.Bookingdata.service_date = serviceDateTimeDBLocal.substring(
            0,
            serviceDateTimeDBLocal.indexOf(' ')
          );
          this.totalBids = collection1.booking_has_many_placed_bids.length;
          this.Bidsdata = collection1.booking_has_many_placed_bids;
          this.completed_by = collection1.bid_completed_by ? collection1.bid_completed_by.id : 0;
          this.showDriver = 0;
          this.location_map_image_url = collection1.location_map_image_url;
          if (!collection1.location_map_image) {
            this.isFatchMapImage = true;
            this.fetchMapImage(bookingId);
          }
        })
        .catch((error) => {
          this.Bookingdata = null;
          this.currentBooking = null;

          this.$toast.error(error.response.data.message);
        })
        .finally(() => {
          this.isFetchingBooking = false;
          setTimeout(() => {
            this.setheight = this.$refs.bookingFlow.clientHeight;
            // console.log('table height',this.$refs.tableRef.clientHeight);
            // this.tableHeight =  this.$refs.tableRef.clientHeight;
            // if(this.$refs.tableRef.clientHeight > window.innerHeight)
            // {
            //   this.boxSetHeight = this.$refs.tableRef.clientHeight + 138;
            // }
            // else{
            //   // this.tableHeight = this.setheight - 138;
            //   if(this.$refs.tableRef.clientHeight > this.setheight || window.innerWidth <= 1440)
            //   {
            //     this.boxSetHeight = this.setheight
            //   }
            //   else{
            //     this.boxSetHeight = this.setheight - 113;
            //   }
            // }
            // console.log('booking',this.$refs.bookingFlow.clientHeight);
            // this.boxSetHeight = this.$refs.tableRef.clientHeight;
            // if(this.$refs.tableRef.clientHeight < 800)
            // {
            //   this.setheight = this.$refs.bookingFlow.clientHeight + 130;
            // }
            // else{
              // this.setheight = this.$refs.bookingFlow.clientHeight + 100;
            // }
              // console.log('window', this.setheight)
              // console.log(this.$refs.bookingFlow.clientHeight);
              // if(this.$refs.bookingFlow.clientHeight >= 800)
              // {
              //   this.setheight = parseInt(this.$refs.bookingFlow.clientHeight) + 200;
              //   if(this.setheight >= this.$refs.bookingMain.clientHeight)
              //   {
              //     let calheight = parseInt(this.windowheight) - parseInt(this.$refs.bookingFlow.clientHeight);
              //     this.setheight = parseInt(calheight) + parseInt(this.windowheight);
              //   }
              // }
              // else{
              //   this.setheight = window.innerHeight + 100;
              // }
          }, 1500);
        });
    },
    fetchMapImage(bookingId) {
      this.$http
        .post(`customer/booking/getGoogleStaticMap`, {
          booking_id: bookingId,
        })
        .then((res) => {
          this.location_map_image_url = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isFatchMapImage = false;
        }, 1000);
    },
    showDriverProfile(booking_id, bidId, status) {
      if (status == 1) {
        var url = `customer/map/driver/show/${bidId}`;
      } else {
        var url = `customer/booking/show/${booking_id}/driver_profile/${bidId}`;
      }
      this.isFetchingDriver = true;
      this.currentDriverId = bidId;
      this.$http
        .get(url)
        .then((res) => {
          this.driverData = res.data.data;
          //currently this is working by compute property isShowAccptRejectButton1
          if (res.data.data.bid_active_time) {
            if (res.data.data.bid_active_time > moment(new Date()).format('YYYY-MM-DD HH:mm:ss')) {
              this.isShowAccptRejectButton = true;
            }
          } else {
            this.isShowAccptRejectButton = true;
          }
          this.showDriver = status;
        })
        .catch((error) => {
          this.currentDriverId = '';
          this.driverData = null;
          this.$toast.error(error.response.data.message);
        })
        .finally(() => {
          this.isFetchingDriver = false;
        });
    },
    RejectBid() {
      // this.isFetchingBooking = true;
      this.isRejectBid = 1;
      this.$http
        .post(`customer/booking/bid_reject`, {
          booking_id: this.driverData.booking_id,
          place_bid_id: this.driverData.id,
        })
        .then((res) => {
          this.$toast.open('Bid Rejected', 'success');
          this.showDriverProfile(res.data.data.booking_id, res.data.data.user_id);
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
        })
        .finally(() => {
          // this.isFetchingBooking = false;
          this.isRejectBid = 0;
        });
    },
    callMessage(bookingId, driverId) {
      this.$http
        .post(`chat/connect_driver`, {
          booking_id: this.driverData.booking_id,
          driver_id: this.driverData.user_id,
        })
        .then((res) => {
          // this.$toast.open('Successfully connected', 'success');
          this.$router.push({
            name: 'message',
            params: { driver_id: this.driverData.user_id, bookingId: this.driverData.booking_id },
          });
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
        })
        .finally(() => {});
    },
    showBooking(bookingId) {
      if (this.currentBooking === bookingId) {
        this.currentBooking = null;
        return;
      } else {
        this.currentBooking = bookingId;
        this.getspecificBooking(bookingId);
      }
    },
    handleFileImport() {
      handleFileImport;
      // this.isSelecting = true;

      // window.addEventListener(
      //   'focus',
      //   () => {
      //     this.isSelecting = false;
      //   },
      //   { once: true }
      // );

      // // Trigger click on the FileInput
      // this.$refs.add_attachments.click();
    },
    onAttachFileChanged(e) {
      if (e.target.files.length == 0) {
        return;
      }
      const formdata = new FormData();
      const accpetdType = e.target.accept.split(',');
      var i = 0;
      for (let file of event.target.files) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        formdata.append(`attechments[${i}]`, file);
        i++;
        // if (!isNaN(key) && accpetdType.includes(e.target.files[key].type)) {
          // formdata.append(`attechments[${key}]`, e.target.files[key]);
        // }
        // if (e.target.files[key].type && !accpetdType.includes(e.target.files[key].type)) {
        //   this.showInvalidFileError = true;
        // }
      }
      // e.target.files.forEach((e, i) => {});
      // if (!this.showInvalidFileError) {
        formdata.append('booking_id', this.Bookingdata.id);

        this.$http
          .post(`/customer/booking/add-attachments`, formdata, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(async (res) => {
            this.$toast.open('Attachment added successfully', 'success');
            this.getspecificBooking(this.Bookingdata.id);
          })
          .catch((error) => {
            this.$toast.error('Error in add attachment');
          })
          .finally(() => {
            this.isFormSubmit = false;
            this.company_logo_preview = null;
            this.image_preview = null;
          });
      // }
      // if (this.showInvalidFileError) {
      //   this.showInvalidFileError = false;
      //   this.$toast.error('Please upload valid file type');
      // }
    },
    deleteAttachment(id) {
      this.$http
        .post(`/customer/booking/remove-attachments`, {
          _method: 'delete',
          booking_id: this.currentBooking,
          attechment_id: id,
        })
        .then((res) => {
          this.$toast.open('Attachment delete successfully', 'success');
          this.getspecificBooking(this.Bookingdata.id);
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
        })
        .finally(() => {});
    },
    deleteBooking(id, index) {
      this.CloseModel();
      this.isDeletetingBooking = true;
      this.$http
        .post(`/customer/booking/delete/${id}`, {
          _method: 'delete',
        })
        .then((res) => {
          this.rows.splice(index, 1);
          this.currentBooking = null;
          this.tableInfo.total = this.tableInfo.total - 1;
          this.$toast.open('Booking delete successfully', 'success');
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
        })
        .finally(() => {
          this.isDeletetingBooking = false;
        });
    },
    searchBooking() {
      this.getbooking();
    },
    sortBooking(Field) {
      if (this.serverParams.hasOwnProperty('orderByField')) {
        if (this.serverParams.orderByField === Field) {
          this.updateParams({
            orderByField: Field,
            orderByType: this.serverParams.orderByType === 'asc' ? 'desc' : 'asc',
          });
        } else {
          this.updateParams({
            orderByField: Field,
            orderByType: this.serverParams.orderByType === 'asc' ? 'desc' : 'asc',
          });
        }
      } else {
        this.updateParams({
          orderByField: Field,
          orderByType: 'asc',
        });
      }
      this.getbooking();
    },
    updateParams(newProps) {
      // this.serverParams = { ...newProps };
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    queryForKeywords(event) {
      const value = event.target.value;
      const key_press = event.keyCode;
      if (key_press == 8 || key_press == 46) {
        if (value != '') {
          this.deleteDateFilter = 1;
        } else {
          this.serverParams.filterByDate = '';
          this.deleteDateFilter = 0;
          this.getbooking();
        }
      } else {
        this.deleteDateFilter = 0;
      }
    },
    pagechanged(e) {
      this.serverParams.page = Number(e);
      this.getbooking();
    },
    perPagechanged(e) {
      this.serverParams.perPage = Number(e);
      this.getbooking();
    },
    openModalForShowImage(openModalForShowImage) {
      this.showImageinModalPath = openModalForShowImage;
    },

    closeImageModel() {
      this.showImageinModalPath = '';
    },
    referenceNoCopyToClipBoard(textToCopy) {
      navigator.clipboard.writeText(textToCopy);
    },
    setRating(rating) {
      this.form.rating = rating;
    },
    onSubmit() {
      this.isReviewSubmit = true;
      this.form.booking_id = `${this.currentBooking}`;
      this.form.user_id = this.Bookingdata.bid_completed_by.user_id;
      this.$http
        .post('/customer/booking/add_rating_review', this.form)
        .then((res) => {
          (this.givenrating = this.form.rating), (this.form.review = this.form.review);
          this.alreadyGiveReview = true;
          this.$toast.open('Review add successfully', 'success');
          this.getspecificBooking(this.currentBooking);
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
        })
        .finally(() => {
          this.form.rating = 0;
          this.isReviewSubmit = false;
        });
    },
    CloseModel() {
      document.getElementById('deleteBookingModalClose').click();
    },
  },
};
</script>

<style>
.active {
  display: block;
}
.option-heading {
  position: relative;
  display: inline-block;
}
.option-heading:after {
  content: '';
  position: absolute;
  display: block;
  width: 10px;
  height: 5px;
  background-size: contain;
  background-repeat: no-repeat;
  top: 6px;
  right: -15px;
  background-image: url('~@assets/images/triangle.svg');
}
.option-heading.active:after {
  transform: rotate(180deg);
}

/* Helpers */
.is-hidden {
  display: none;
}

.nth-child-border {
  border-bottom: 2px solid #00a6e3 !important;
  border-top: 2px solid #00a6e3 !important;
}
.first-child-border {
  border-bottom: 2px solid #00a6e3 !important;
  border-top: 2px solid #00a6e3 !important;
}
.bid-button .btn.btn-primary {
  min-width: auto;
  font-size: 16px;
  line-height: 20px;
  padding: 10px 12px;
}
.vue-star-rating-rating-text {
  margin-left: 10px;
  font-weight: 700;
  color: #00a6e3;
}
.review-spinner.spinner-border {
  height: 2rem !important;
  width: 2rem !important;
}
</style>
