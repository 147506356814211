<template>
  <!-- eslint-disable -->
  <div class="pagination-table-footer d-flex">
    <div class="tshow">
      <label>Show</label>
      <select class="pagination-select form-select" aria-label="per-page-selector" @change="perPageChanged">
        <option
          :value="page"
          v-for="page in pageSelector"
          :selected="page == perPage"
          :key="`page-selector-${page}`"
        >
          {{ page }}
        </option>
      </select>
    </div>
    <div class="pagination-main">
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item">
            <a
              class="page-link prev"
              :class="{ active: value <= 1 ? '' : 'active' }"
              @click.prevent="previous"
            >
              Previous
            </a>
          </li>
          <li class="page-item" v-for="(item, index) in items" :key="`${item}-${index}`">
            <a
              class="page-link"
              :class="{ activePage: item == value }"
              @click.prevent="onClickPage(item)"
              >{{ item }}</a
            >
          </li>
          <li class="page-item">
            <a
              class="page-link next"
              :class="{ active: value >= length ? '' : 'active' }"
              @click.prevent="next"
            >
              Next
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'pagination',
  props: {
    perPage: {
      type: Number,
      default: 10,
    },
    value: {
      type: Number,
      default: 1,
    },
    totalRows: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selected: null,
      totalVisible: 10,
      maxButtons: 0,
      pageSelector: [10, 15, 20, 25, 50, 100],
    };
  },

  computed: {
    items() {
      const totalVisible = parseInt(this.totalVisible, 10);

      if (totalVisible === 0) {
        return [];
      }

      const maxLength = Math.min(
        Math.max(0, totalVisible) || this.length,
        Math.max(0, this.maxButtons) || this.length,
        this.length
      );

      if (this.length <= maxLength) {
        return this.range(1, this.length);
      }

      const even = maxLength % 2 === 0 ? 1 : 0;
      const left = Math.floor(maxLength / 2);
      const right = this.length - left + 1 + even;

      if (this.value > left && this.value < right) {
        const firstItem = 1;
        const lastItem = this.length;
        const start = this.value - left + 2;
        const end = this.value + left - 2 - even;
        const secondItem = start - 1 === firstItem + 1 ? 2 : '...';
        const beforeLastItem = end + 1 === lastItem - 1 ? end + 1 : '...';

        return [1, secondItem, ...this.range(start, end), beforeLastItem, this.length];
      } else if (this.value === left) {
        const end = this.value + left - 1 - even;
        return [...this.range(1, end), '...', this.length];
      } else if (this.value === right) {
        const start = this.value - left + 1;
        return [1, '...', ...this.range(start, this.length)];
      } else {
        return [...this.range(1, left), '...', ...this.range(right, this.length)];
      }
    },
    length() {
      return Math.ceil(this.totalRows / this.perPage);
      return this.totalRows > this.perPage
        ? parseInt(((this.totalRows + this.perPage - 1) / this.perPage).toString(), 10)
        : 1;
    },
  },

  methods: {
    next() {
      if (this.value >= this.length) return;
      this.$emit('pagechanged', this.value + 1);
    },
    previous() {
      if (this.value <= 1) return;
      this.$emit('pagechanged', this.value - 1);
    },
    onClickPage(page) {
      if (this.value != page) {
        this.$emit('pagechanged', page);
      }
    },
    range(from, to) {
      const range = [];
      let f = from;
      f = f > 0 ? f : 1;
      for (let i = f; i <= to; i += 1) {
        range.push(i);
      }
      return range;
    },
    perPageChanged(e) {
      this.$emit('perPagechanged', e.target.value);
    },
  },
};
</script>

<style>
.tshow {
  width: 80px;
}
.tshow .form-select{
  padding: 5px 8px;
}
.tshow .form-select:focus,
.tshow .form-select:active{box-shadow: unset;outline: none;}
.pagination-main {
  margin-left: 30px;
}
.pagination-table-footer {
  width: 100%;
  align-items: flex-end;
  margin: 25px 0;
}
.activePage {
  display: block;
  background-color: #00A6E3;
  border-collapse: #00A6E3;
  color: #ffffff !important;
}
.activePage:hover{
  background-color: #4E6B80;
  border-color: #4E6B80;
  color: #ffffff;
}
.page-link{
  color: #000000;
}
.pagination-main,.pagination-select{
  cursor: pointer !important;
}
</style>
